import { render } from 'react-dom';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import React, { Suspense } from 'react';

const Index = React.lazy(() => import('./pages/index'));

const App = () => {
  return (
    <Suspense fallback={<div />}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Index />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

const rootEl = document.getElementById('root');
render(<App />, rootEl);
